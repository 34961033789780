import React from "react";
import { useRef } from "react";
import "../styles/index.scss";
import Header from "../components/Header";
import AboutUs from "../components/AboutUs";
import ContactForm from "../components/ContactForm";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layer2 from "../images/Layer3.png";
import { motion } from "framer-motion";

const IndexPage = () => {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    contactRef.current.scrollIntoView();
  };

  return (
    <div className="container">
      <Helmet>
        <title>Ljungs Måleri & Byggnadsvård</title>
      </Helmet>
      <Header />
      <div className="hero__wrapper">
        <div className="hero__section">
          <motion.div
            initial={{
              opacity: "0%",
              x: "-20rem",
            }}
            animate={{
              opacity: "100%",
              x: "0rem",
              zIndex: 2,
            }}
            transition={{ duration: 1 }}
            className="hero__text"
          >
            <h1>Det lokala företaget inom Måleri & Byggnadsvård </h1>
            <h2>
              Det lilla måleriföretaget med det stora hjärtat.<br></br> Alltid
              med kundens nöjdhet i fokus
            </h2>
            <div className="hero__buttons">
              <button onClick={() => scrollToContact()}>Begär Offert</button>
              <button>
                <Link to="/gallery">Galleri</Link>
              </button>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: "0%", x: "20rem" }}
            animate={{ opacity: "100%", x: "0rem", zIndex: 1 }}
            transition={{
              // delay: 1,
              duration: 1,
            }}
            className="image__section"
          >
            <img alt="Siloutte av en målare" src={Layer2}></img>
          </motion.div>
          {/* <div dangerouslySetInnerHTML={{ __html: SVGAboutUsDivider }} /> */}
        </div>
      </div>
      <AboutUs></AboutUs>
      <div ref={contactRef}>
        <ContactForm />
      </div>
    </div>
  );
};

export default IndexPage;
