import React from "react";
import "../styles/about__us.scss";
import { Link } from "gatsby";
import {
  whatCanWeDo,
  whoAreWe,
  earlierProject,
  SVGPaintRoller,
  SVGPaintBucket,
} from "../content/indexContent";

export default function AboutUs() {
  return (
    <div className="about__us">
      <div className="about__us__wrapper">
        <div className="about__us__text__div">
          <h1>Vem är vi?</h1>
          <p>{whoAreWe}</p>
        </div>
        <div
          className="about__us__text__div"
          dangerouslySetInnerHTML={{ __html: SVGPaintRoller }}
        />
        <div className="about__us__text__div">
          <p className="water__mark">Ljungs Måleri & byggnadsvård</p>
        </div>
        <div className="about__us__text__div">
          <h1 style={{ textAlign: "left" }}>Vad kan vi hjälpa dig med?</h1>
          <p>{whatCanWeDo}</p>
        </div>
        <div className="about__us__text__div">
          <h1>Våra tidigare projekt</h1>
          <p>{earlierProject}</p>
          <button
            style={{ textShadow: "2px 2px 5px black" }}
            className="gallery__button"
          >
            <Link className="" to="/gallery">
              Galleri
            </Link>
          </button>
        </div>
        <div
          className="about__us__text__div"
          dangerouslySetInnerHTML={{ __html: SVGPaintBucket }}
        />
      </div>
    </div>
  );
}
