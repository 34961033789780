export const whoAreWe =
  "Ljungs måleri är ett erfaret måleri med kund och byggnadsvård i fokus. Med lång och bred erfarenhet i branschen hjälper vi dig med dina önskemål och tar fram aktuella åtgärdsförslag för just ditt projekt. Hör gärna av dig för gratis offert via kontaktformuläret eller bläddra i galleriet och kika in lite avslutade projekt.";

export const whatCanWeDo =
  "Vi hjälper er med det mesta inom måleri & byggnadsvård, nytt som gammalt. Spackling, tapetsering, invändig målning såsom väggar, tak och snickerier samt utvändig målning för att ta några exempel. Ett brett samarbete med övriga yrkeskategorier ger även möjligheten för totalentreprenad och ett helhetsansvar under hela byggprocessen.";

export const earlierProject =
  " Önskar du att spana in och se vad vi har för tidigare projekt? Vi har samlat en del under Galleri. Ta gärna en titt för inspiration";

export const SVGAboutUsDivider =
  ' <svg class="svg__about_us_divider" width="1440" height="196" viewBox="0 0 1440 196" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M142.271 14.3651C29.5118 -8.45185 -19.5593 23.8722 -30 42.8863V196H1398.58C1451.11 135.455 1522.4 11.9633 1387.32 2.35618C1218.47 -9.65272 1207.22 28.3755 1149.96 42.8863C1092.7 57.397 1025.65 14.3651 957.132 2.35618C888.615 -9.65272 852.888 27.3748 777.031 42.8863C701.173 58.3978 659.084 14.3651 577.842 2.35618C496.601 -9.65272 454.512 27.3748 385.016 42.8863C315.521 58.3978 283.22 42.8863 142.271 14.3651Z" fill="#313131"/> </svg>';

export const SVGPaintRoller = `<svg
            class="svg__paintroller"
            width="151"
            height="200"
            viewBox="0 0 151 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_14_35)">
              <path
                d="M96.4249 29.8879L41.5546 66.3922C36.0526 70.0526 34.555 77.5038 38.2154 83.0058L48.1711 97.9704C51.8315 103.472 59.2827 104.97 64.7847 101.31L119.655 64.8054C125.157 61.145 126.655 53.6938 122.994 48.1918L113.038 33.2272C109.378 27.7252 101.927 26.2275 96.4249 29.8879ZM58.1475 91.3333L48.1918 76.3687L103.062 39.8643L113.028 54.8223L58.1475 91.3333Z"
                fill="#dd7640"
              />
              <path
                d="M114.625 111.327L107.988 101.351C104.328 95.8491 96.8766 94.3514 91.3746 98.0118L56.4572 121.242L36.5457 91.3126C31.0437 94.973 29.5461 102.424 33.2065 107.926L46.4808 127.879C50.1412 133.381 57.5923 134.879 63.0943 131.218L98.0118 107.988L104.649 117.965C103.326 118.845 102.407 120.214 102.094 121.772C101.781 123.33 102.099 124.948 102.979 126.271L119.572 151.212C120.452 152.535 121.822 153.455 123.38 153.768C124.938 154.081 126.556 153.762 127.879 152.882L137.855 146.245C139.178 145.365 140.097 143.995 140.411 142.437C140.724 140.88 140.405 139.261 139.525 137.938L122.932 112.997C122.052 111.674 120.682 110.755 119.125 110.442C117.567 110.129 115.948 110.447 114.625 111.327Z"
                fill="#dd7640"
              />
            </g>
            <defs>
              <clipPath id="clip0_14_35">
                <rect
                  width="143.79"
                  height="143.79"
                  fill="white"
                  transform="translate(0 79.6459) rotate(-33.6352)"
                />
              </clipPath>
            </defs>
          </svg>`;

export const SVGPaintBucket = `<svg class="svg__paintbucket" width="304" height="304" viewBox="0 0 304 304" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M200.095 25.384C184.123 25.384 170.911 39.3554 168.733 57.532L61.2814 164.996L159.803 263.517C163.331 267.047 167.521 269.846 172.132 271.756C176.742 273.666 181.684 274.65 186.675 274.65C191.666 274.65 196.608 273.666 201.218 271.756C205.829 269.846 210.019 267.047 213.547 263.517L267.267 209.785C270.796 206.257 273.596 202.067 275.506 197.456C277.416 192.846 278.399 187.904 278.399 182.913C278.399 177.922 277.416 172.98 275.506 168.37C273.596 163.759 270.796 159.569 267.267 156.041L231.749 120.511V62.8394C231.749 42.1547 217.563 25.384 200.083 25.384H200.095ZM193.762 62.8394V82.5107L206.429 95.1774V62.8394C206.429 58.6974 203.591 55.3534 200.095 55.3534C196.587 55.3534 193.762 58.6974 193.762 62.8394ZM193.762 137.75V118.332L249.369 173.951C251.743 176.327 253.077 179.548 253.077 182.907C253.077 186.265 251.743 189.487 249.369 191.862L195.624 245.607C193.249 247.981 190.027 249.315 186.669 249.315C183.31 249.315 180.089 247.981 177.713 245.607L97.1027 164.996L168.416 93.6827V137.763L168.454 139.384H193.914C193.813 138.845 193.762 138.298 193.762 137.75Z" fill="#dd7640"/>
<path d="M90.4527 213.75C95.7682 219.064 99.3885 225.835 100.856 233.206C102.323 240.578 101.571 248.219 98.6952 255.163C95.8194 262.108 90.9488 268.043 84.6995 272.219C78.4502 276.395 71.1028 278.624 63.5866 278.624C56.0705 278.624 48.7231 276.395 42.4738 272.219C36.2244 268.043 31.3539 262.108 28.4781 255.163C25.6023 248.219 24.8505 240.578 26.3176 233.206C27.7848 225.835 31.4051 219.064 36.7206 213.75L63.5866 186.884L90.4527 213.737V213.75Z" fill="#dd7640"/>
</svg>
`;

export const SVGContactUsDivider = ` <svg class="svg_contact_us_divider" width="1440" height="196" viewBox="0 0 1440 196" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M142.271 14.3651C29.5118 -8.45185 -19.5593 23.8722 -30 42.8863V196H1398.58C1451.11 135.455 1522.4 11.9633 1387.32 2.35618C1218.47 -9.65272 1207.22 28.3755 1149.96 42.8863C1092.7 57.397 1025.65 14.3651 957.132 2.35618C888.615 -9.65272 852.888 27.3748 777.031 42.8863C701.173 58.3978 659.084 14.3651 577.842 2.35618C496.601 -9.65272 454.512 27.3748 385.016 42.8863C315.521 58.3978 283.22 42.8863 142.271 14.3651Z" fill="#424242"/> </svg>
`;
