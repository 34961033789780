export const loadingSvg = `      <svg
        class="loadingSVG
        width="197px"
        height="197px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.8340283569641368s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(32.72727272727273 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.7506255212677231s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(65.45454545454545 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.6672226855713094s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(98.18181818181819 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.5838198498748958s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(130.9090909090909 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.5004170141784821s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(163.63636363636363 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.4170141784820684s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(196.36363636363637 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.3336113427856547s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(229.0909090909091 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.25020850708924103s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(261.8181818181818 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.16680567139282734s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(294.54545454545456 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="-0.08340283569641367s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(327.27272727272725 50 50)">
          <rect
            x="47"
            y="24.5"
            rx="2.08"
            ry="2.08"
            width="6"
            height="13"
            fill="#ffffff"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="0.9174311926605504s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>`;
