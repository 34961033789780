import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "../styles/ContactForm.scss";
import { loadingSvg } from "../content/loadingSvg";
import ManualContact from "./ManualContact";

export default function ContactForm() {
  const form = useRef();
  const [FormName, setFormName] = useState("");
  const [FormEmail, setFormEmail] = useState("");
  const [FormPhoneNumber, setFormPhoneNumber] = useState("");
  const [FormSubject, setFormSubject] = useState("");
  const [FormMessage, setFormMessage] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);

  function sendEmail() {
    const formInfo = document.querySelector("#formInfo");
    setSendingEmail(true);

    emailjs
      .sendForm(
        "service_aywuwic",
        "template_nmbzffj",
        form.current,
        "8cJPe6smE00fEN7E6"
      )
      .then(
        (result) => {
          setFormName("");
          setFormEmail("");
          setFormPhoneNumber("");
          setFormSubject("");
          setFormMessage("");
          formInfo.classList.add("successfullySentEmail");
          formInfo.innerText = "Tack för ditt mail, Vi svarar så fort vi kan!";
          setSendingEmail(false);
        },
        (error) => {
          console.log(error.text);
          formInfo.innerText =
            "Tyvärr uppstod ett problem. Försök gärna igen senare!";
        }
      );
  }

  const verifyForm = (e) => {
    e.preventDefault();
    const formInfo = document.querySelector("#formInfo");
    if (
      FormName !== "" &&
      FormEmail !== "" &&
      FormSubject !== "" &&
      FormMessage !== ""
    ) {
      sendEmail();
    } else {
      console.log("not all fields are filled");
      formInfo.innerText = " Kunde ej skicka - Vänligen fyll i alla fält!";
      formInfo.style.display = "block";
    }
  };

  return (
    <div className="contact__component">
      <div className="contact__wrapper">
        <form onSubmit={verifyForm} ref={form} id="form1">
          <h1>Kontakt</h1>
          <label htmlFor="name">Namn</label>
          <input
            onChange={(e) => setFormName(e.target.value)}
            value={FormName}
            type="text"
            name="name"
            id="name"
          ></input>

          <label htmlFor="email">E-post</label>
          <input
            onChange={(e) => setFormEmail(e.target.value)}
            value={FormEmail}
            type="text"
            name="email"
            id="email"
          ></input>
          <label htmlFor="phone">Telefonnummer</label>
          <input
            onChange={(e) => setFormPhoneNumber(e.target.value)}
            value={FormPhoneNumber}
            type="text"
            name="phone"
            id="phone"
          ></input>
          <label htmlFor="subject">Ämne</label>
          <input
            onChange={(e) => setFormSubject(e.target.value)}
            value={FormSubject}
            type="text"
            name="subject"
            id="subject"
          ></input>
          <label htmlFor="message">Ditt Meddelande</label>
          <textarea
            onChange={(e) => setFormMessage(e.target.value)}
            value={FormMessage}
            type="textarea"
            name="message"
            id="message"
          ></textarea>
          <div id="formInfo"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <button
              form="form1"
              type="submit"
              value="Skicka"
              onClick={(e) => verifyForm(e)}
            >
              <svg width="15" height="11" viewBox="0 0 15 11" fill="none">
                <path
                  d="M13.5 11H1.5C1.10218 11 0.720644 10.8551 0.43934 10.5973C0.158035 10.3394 0 9.98967 0 9.625V1.31519C0.0168245 0.960981 0.182257 0.626422 0.461759 0.381357C0.74126 0.136292 1.11323 -0.000334977 1.5 6.1677e-07H13.5C13.8978 6.1677e-07 14.2794 0.144866 14.5607 0.402729C14.842 0.660591 15 1.01033 15 1.375V9.625C15 9.98967 14.842 10.3394 14.5607 10.5973C14.2794 10.8551 13.8978 11 13.5 11ZM1.5 2.65925V9.625H13.5V2.65925L7.5 6.325L1.5 2.65925ZM2.1 1.375L7.5 4.675L12.9 1.375H2.1Z"
                  fill="black"
                />
              </svg>{" "}
              Skicka
            </button>
            {!sendingEmail ? (
              ""
            ) : (
              <div dangerouslySetInnerHTML={{ __html: loadingSvg }} />
            )}
          </div>
        </form>
        <ManualContact />
      </div>
    </div>
  );
}
